.notification {
  display: flex;
  padding: 1.2rem;
  border-radius: 10px;
  font-size: large;
}

.notifContainer:not(:first-child) {
  margin-top: 1rem;
}

.notification[new="true"] {
  background-color: var(--very-light-grayish-blue);
  border: solid 1px rgb(150, 150, 150, 0.1);
}

.avatar {
  width: 4rem;
  height: 4rem;
}

.textContainer {
  display: inline-flex;
  color: var(--dark-grayish-blue);
}

.username {
  font-weight: 800;
  cursor: pointer;
  white-space: pre;
  margin-right: 0.5rem;
  color: var(--very-dark-blue);
}

.notificationBlock {
  padding-left: 1em;
}

.relativeDate {
  color: var(--dark-grayish-blue);
}

.notifPicture {
  max-height: 64px;
  margin-left: auto;
}

.quote {
  display: flex;
  color: var(--dark-grayish-blue);
  font-size: large;
  margin: 0 2.4rem;
}

.quote > div:first-child {
  min-width: 64px;
}
.quote > div:last-child {
  padding: 1rem;
  border: solid 1px rgb(150, 150, 150, 0.2);
  border-radius: 10px;
  transition: 0.5s;
}
.quote > div:last-child:hover {
  background-color: var(--very-light-grayish-blue);
  transition: 0.5s;
}

a {
  text-decoration: none;
  font-weight: 800;
  color: var(--very-dark-blue);
}

.blob {
  position: relative;
  top: -0.6rem;
  left: -0.2rem;
  background: rgb(255, 0, 0, 1);
  border-radius: 50%;
  margin: 1rem;
  height: 0.5rem;
  width: 0.5rem;

  box-shadow: 0 0 0 0 rgb(255, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}
