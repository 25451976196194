.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.headerTitle {
  margin: 0;
  font-weight: 800;
}

.markAsRead {
  display: flex;
  align-items: flex-end;
  font-size: large;
  color: var(--dark-grayish-blue);
  cursor: pointer;
}

.pill {
  padding: 0.6rem 1.5rem;
  margin-left: 1rem;
  border-radius: 5px;
  font-weight: 800;
  background-color: var(--very-dark-blue);
  color: #fff;
}
