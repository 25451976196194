:root {
  --very-light-grayish-blue: hsl(210, 60%, 98%);
  --light-grayish-blue-1: hsl(211, 68%, 94%);
  --light-grayish-blue-2: hsl(205, 33%, 90%);
  --grayish-blue: hsl(219, 14%, 63%);
  --dark-grayish-blue: hsl(219, 12%, 42%);
  --very-dark-blue: hsl(224, 21%, 14%);
}

body {
  margin: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background-color: var(--very-light-grayish-blue);
}

#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex {
  display: flex;
}
